import React from "react";
import { Link } from "gatsby";
import { withAuthentication } from "../hoc/withAuthentication";
import Main from "../components/main";
import Header from "../components/header";
import Footer from "../components/footer";
import Banner from "../components/banner";
import ContentArea from "../components/contentArea";
import MasonryTiles from "../components/masonryTiles";
import TextArea from "../components/textArea";
import ValueRow from "../components/valueRow";
import DisplayName from "../components/displayname";
import GridContainer from "../components/gridContainer";
import NavPanel from "../components/navPanel";
import Consts from "../config/consts";
import Api from "../config/api";
import util from "../config/util";
import ObserverHome from "./u/ObserverHome";
import { SEO } from "../components/seo";

export default withAuthentication(
    {
        requiredAuthLevels: [Consts.AUTH_LEVELS.AUTHENTICATED, Consts.AUTH_LEVELS.UNAUTHENTICATED], // INDEX MUST HAVE BOTH AUTH AND UNAUTH IN ORDER TO AVOID FAILURE LOOPING
        redirectPage: "/",
    },
    class Home extends React.Component {
        constructor(props) {
            super(props);
            this.main_ref = React.createRef();
            this.trending_section_ref = React.createRef();
            this.picked_section_ref = React.createRef();

            this.state = {
                windowSize: window.innerWidth,
                user: props.auth.user || null,
                topUsers: [],

                showBidOverlay: false,
                bidListing: null,
            };
        }
        
        updatedUser = () => {
            console.log("hook called mount unmount didUpdate >> ", this.state.auth.user)
        }

        componentDidMount() {
            let resize_timeout = null;
            window.onresize = (val) => {
                clearTimeout(resize_timeout);
                resize_timeout = setTimeout(() => {
                    this.setState({
                        windowSize: window.innerWidth,
                    });
                }, 200);
            };

            // Get the top users to display
            Api.market({
                endpoint: "/content/featured/profiles",
                method: "GET",
            })
                .then((result) => {
                    this.setState({
                        topUsers: result.data,
                    });
                })
                .catch((e) => {});
        }

        onShowLogin = () => {
            if (this.main_ref) {
                this.main_ref.current.showLoginOverlay();
            }
        };

        onShowBidOverlay = (listing) => {
            if (this.main_ref) {
                this.main_ref.current.showBidOverlay(listing);
            }
        };

        goToTrending = () => {
            window.scrollTo({
                top: this.trending_section_ref.current.offsetTop,
                behavior: "smooth",
            });
        };
        goToPicked = () => {
            window.scrollTo({
                top: this.picked_section_ref.current.offsetTop,
                behavior: "smooth",
            });
        };

        render() {
            <ObserverHome value={this.props.auth.user} didUpdate={this.updatedUser} />
            return (
                <Main ref={this.main_ref} title={"BMinted"} auth={this.props.auth} prices={this.props.prices} providers={this.props.providers} currentChain={this.props.currentChain} chains={this.props.chains}>
                    <span ref={this.trending_section_ref} />
                    <ContentArea header={"Trending."} extraTopPadding={true}>
                        <Banner windowSize={this.state.windowSize} />
                    </ContentArea>

                    <span ref={this.picked_section_ref} />
                    <ContentArea
                        header={this.state.user ? "Hi, we found these for you." : <span>Hi, want a tailored experience?</span>}
                        subHeader={
                            this.state.user ? (
                                <Link to="/account">Your Preferences</Link>
                            ) : (
                                <a className={"basic_link"} onClick={this.onShowLogin}>
                                    Login | Sign Up
                                </a>
                            )
                        }
                    >
                        {/* <TextArea center={true}>
                            <p className={"caps_and_spaced"}>Picked For You...</p>
                        </TextArea> */}
                        <MasonryTiles contentEndpoint={"/content/tailored"} windowSize={this.state.windowSize} topUsers={this.state.topUsers} maxFetchesAllowed={4} onBid={this.onShowBidOverlay} />
                    </ContentArea>

                    <NavPanel
                        showScrollTop={true}
                        tabs={[
                            {
                                onClick: this.goToTrending,
                                text: "Trending",
                            },
                            {
                                onClick: this.goToPicked,
                                text: "For You",
                            },
                        ]}
                    />
                </Main>
            );
        }
    }
);
export const Head = () => (
    <SEO />
  )
