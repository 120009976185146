import React from "react";
import { Link } from "gatsby";
import * as styles from "./gridContainer.module.css";

export default class GridContainer extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {}

    render() {
        return (
            <div
                className={`
                ${styles.container}
                ${this.props.horizontal === true && styles.container_horizontal}
            `}
                style={{ height: this.props.horizontalHeight ? this.props.horizontalHeight : "auto" }}
            >
                {this.props.children}
            </div>
        );
    }
}
